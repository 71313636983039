import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';

import { Link as LinkModal } from 'gatsby'

import PortfolioContext from '../../context/context';
import GithubButtons from '../GithubButtons/GithubButtons';

import { githubButtons } from '../../mock/data';

import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'




const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;
  //const { isEnabled } = githubButtons;

  const isEnabled = false;


/*
  <p className="footer__text">
  © {new Date().getFullYear()} - Template developed by{' '}
  <a href="https://github.com/cobidev" target="_blank" rel="noopener noreferrer">
    Jacobo Martínez
  </a>
</p>
*/




  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
        <span style={{ minWidth: 200 }}>
                <a
                  key={'Impressum'}
                  href={"https://firmen.wko.at/dipl-ing-christian-lechner/wien/?firmaid=ad54cf39-cfc8-400f-ab6c-aac0ec4cce74"}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={'Impressum'}
                >
                Impressum
                </a>
                </span>
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || ''}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}


                <span style={{ minWidth: 220 }}>
                <LinkModal to="/datenschutz/" state={{ modal: true }}> Datenschutz</LinkModal>
              </span>
                </div>
        <hr />
       
        {isEnabled && <GithubButtons />}
      </Container>
    </footer>
  );
};

export default Footer;
